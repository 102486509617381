const stripNamespace = (modelName: string): string => {
    if (!modelName) {
        console.warn("Could not find the modelName for some reason. Results may not be valid.")
        return modelName;
    }
    // Replace "App\Models\" with an empty string
    return modelName.replace(/^App\\Models\\/, '');
};

const pascalToKebabCase = (str: string): string => {
    // Insert a hyphen before each uppercase letter and convert the string to lowercase
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

const pluralize = (type: string): string => {
    // Nullable support
    if (!type){
        return type;
    }

    // Pluralize to match Laravel polymorphic type to database table names standard
    return type + (type.endsWith('s') ? 'es' : 's');
};

const cleanModelNameToEndpoint = (modelName: string): string => {
    if(!modelName){
        console.warn("Could not find the modelName for some reason. Results may not be valid.")
        return modelName;
    }
    const strippedName = stripNamespace(modelName);
    const kebabCaseName = pascalToKebabCase(strippedName);
    return pluralize(kebabCaseName);
};

export {cleanModelNameToEndpoint, pluralize, stripNamespace, pascalToKebabCase}