import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    SimpleShowLayoutProps,
    ReferenceField,
    ShowButton,
    NumberField,
} from 'react-admin';
import Typography from '@mui/material/Typography';

interface CloverApiErrorShowProps extends SimpleShowLayoutProps {
}

const CloverApiErrorShow = (props: CloverApiErrorShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>
            <Typography variant="h6" component="h3" gutterBottom>
                CloverApiError
            </Typography>

            <TextField source="id"/>

            <NumberField source="http_status_code"/>

            <TextField source="error_message"/>

            <ReferenceField source="event_id" reference="events">
                <ShowButton/>
            </ReferenceField>

            <TextField source="endpoint_path"/>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

        </SimpleShowLayout>
    </Show>
}

export default CloverApiErrorShow;
