import React from 'react';
import {
    Show,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    SimpleShowLayout,
    EmailField,
    ReferenceManyField,
    SimpleShowLayoutProps,
    ShowButton,
} from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface CloverEmployeeShowProps extends SimpleShowLayoutProps {
}

const CloverEmployeeShow = (props: CloverEmployeeShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>

            <Typography variant="h6" component="h3" gutterBottom>
                Clover Employee
            </Typography>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <TextField source="name"/>

            <TextField source="nickname"/>

            <EmailField source="email"/>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

            {/* TODO: Endpoint for the "clover-employee-merchants" pivot table */}
            {/*<ReferenceManyField label="Merchants" reference="clover-merchants" target="clover_employee_id" link="show">*/}
            {/*    <Datagrid bulkActionButtons={false}>*/}
            {/*        <TextField source="name"/>    */}
            {/*        <UrlField source="domain" label="Website"/>*/}
            {/*        <TextField source="uuid"/>*/}
            {/*    </Datagrid>*/}
            {/*</ReferenceManyField>*/}


            <Box sx={{marginTop: '16px',}}>

                <Typography variant="h6" component="h3" gutterBottom>
                    Clover Merchants
                </Typography>

                <label>Available soon...</label>
                <br/>
                <br/>

                <Typography variant="h6" component="h3" gutterBottom>
                    Integrations
                </Typography>

                <ReferenceManyField label="Integrations" reference="integrations" target="clover_employee_id">
                    <Datagrid bulkActionButtons={false}>

                        <UrlField source="domain" label="Website"/>
                        <TextField source="uuid"/>
                        <ShowButton/>

                    </Datagrid>
                </ReferenceManyField>

            </Box>
        </SimpleShowLayout>
    </Show>
}

export default CloverEmployeeShow;
