import {SavedQueriesList, FilterLiveSearch} from 'react-admin';
import {Card, CardContent} from '@mui/material';

// @ts-ignore
export const CloverEmployeeFilterSidebar = () => (
    <Card sx={{order: -1, mr: 2, mt: 9, width: 200}}>
        <CardContent>
            <SavedQueriesList/>
            <FilterLiveSearch/>
        </CardContent>
    </Card>
);
