import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    SimpleShowLayoutProps, ReferenceField, FunctionField, Link,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import {ReferencePolymorphicField} from "./ReferencePolymorphicField";
import PriceDisplayCurrencyContext from "./PriceDisplayCurrencyContext";
import {getEnvReceiptUrl} from "../EnvUtils";
import {CloverOrderReceiptIframe} from "./CloverReceiptIframe";
import {RECEIPT_TYPES} from "../Constants";

interface CloverEcomOrderShowProps extends SimpleShowLayoutProps {
}

const CloverEcomOrderShow = (props: CloverEcomOrderShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>
            <Typography variant="h6" component="h3" gutterBottom>
                CloverEcomOrder
            </Typography>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <PriceDisplayCurrencyContext label={"amount"} sourceKey={"amount"}/>

            <TextField source="status"/>

            <ReferencePolymorphicField label={"Origin Order"} sourceKey={"origin_id"} typeKey={"origin_type"}/>

            <DateField source="clover_created_at"/>

            <ReferenceField source="clover_ecom_charge_id" reference="clover-ecom-charges"
                            emptyText={"No charge found! Perhaps this order has not been paid yet!"} link="show">
                <TextField source={"uuid"}/>
            </ReferenceField>

            <ReferenceField source="clover_ecom_refund_id" reference="clover_ecom_refunds" emptyText="Coming soon..."/>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

            <br/>

            <Typography variant="h6" component="h3" gutterBottom>
                Clover Relationships
            </Typography>

            <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show"
                            emptyText={"Order is too old to have a relationship to a merchant. We did not save the merchants to the order yet when it was created."}>
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source="clover_ecom_customer_id" reference="clover-ecom-customers">
                <TextField source="uuid"/>
            </ReferenceField>

            <FunctionField
                label={"Link to Clover Order Receipt"}
                render={(record: { uuid: string }) => {
                    return (
                        <div>
                            <div>
                                <Link to={getEnvReceiptUrl(record.uuid, RECEIPT_TYPES.ORDER)}>
                                    View Official Clover Order Receipt (Publicly Available)
                                </Link>
                            </div>
                            <CloverOrderReceiptIframe uuid={record.uuid}/>
                        </div>
                    );
                }}
            />

        </SimpleShowLayout>
    </Show>
}

export default CloverEcomOrderShow;
