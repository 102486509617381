import {RECEIPT_TYPES} from "./Constants";

const getEnv = () => {
    switch (window.location.hostname){
        case "admin.weeconnectpay.com":
            return "prod"
        case "staging.admin.weeconnectpay.com":
            return "staging"
        default:
            return "local"
    }
}

const getEnvApiUrl = () => {
    switch (getEnv()) {
        case "prod":
            return "api.weeconnectpay.com";
        case "staging":
            return "apidev.weeconnectpay.com";
        default:
        case "local":
            return "weeconnect-api.test";
    }
}

const getEnvCloverReceiptsDomain = (): string => {
    switch (getEnv()) {
        case "prod":
            return "www.clover.com";
        case "staging":
        case "local":
        default:
            return "sandbox.dev.clover.com";
    }
}

const getEnvReceiptUrl = (uuid: string, receiptType: typeof RECEIPT_TYPES[keyof typeof RECEIPT_TYPES]): string => {
    const domain = getEnvCloverReceiptsDomain();

    switch (receiptType) {
        case RECEIPT_TYPES.ORDER:
            return `https://${domain}/r/${uuid}/`;
        case RECEIPT_TYPES.CHARGE:
            return `https://${domain}/tx/p/${uuid}/`;
        default:
            throw new Error(`Invalid receiptType: ${receiptType}`);
    }
};

export {getEnvApiUrl, getEnv, getEnvCloverReceiptsDomain, getEnvReceiptUrl};