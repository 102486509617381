import {
    Datagrid,
    DateField,
    List,
    NumberField,
    ReferenceField, ResourceProps,
    ShowButton,
    TextField
} from 'react-admin';
import React from "react";
import {CloverApiErrorFilterSidebar} from "./CloverApiErrorFilterSidebar";

interface CloverApiErrorListProps extends ResourceProps {
}

export const CloverApiErrorList = (props: CloverApiErrorListProps) => (
    <List {...props} aside={<CloverApiErrorFilterSidebar/>}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>

            <TextField source="id"/>

            <NumberField source="http_status_code"/>

            <TextField source="error_message"/>

            <ReferenceField source="event_id" reference="events">
                <ShowButton/>
            </ReferenceField>

            <TextField source="endpoint_path"/>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

            <ShowButton/>
        </Datagrid>
    </List>
);
