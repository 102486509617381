
import {AuthProvider, fetchUtils} from 'react-admin';
import {getEnvApiUrl} from "./EnvUtils";

const apiUrl: string = getEnvApiUrl();

const authProvider: AuthProvider = {
    login: () => {
        localStorage.setItem('username', 'weeconnectpay');
        return Promise.resolve();
    },
    logout: async () => {
        const url = `https://${apiUrl}/v1/admin/logout`;

        try {
            await fetchUtils.fetchJson(url, {method: 'POST', credentials: 'include'});
            localStorage.removeItem('username');
            return await Promise.resolve();
        } catch (error) {
            console.error('Error logging out:', error);
            return await Promise.reject();
        }
    },
    checkAuth: async () => {
        const url = `https://${apiUrl}/v1/admin/verify`;

        try {
            let response = await fetchUtils.fetchJson(url, {method: 'GET', credentials: 'include'});
            localStorage.removeItem('username');
            if (response.json.result === 'success') {
                return Promise.resolve();
            } else {
                localStorage.removeItem('username');
                return Promise.reject();
            }

        } catch (error) {
            localStorage.removeItem('username');
            return await Promise.reject();
        }
    },
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'ADMIN',
        }),
    getPermissions: () => Promise.resolve(''),
}

export default authProvider;
