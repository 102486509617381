import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    ReferenceOneField,
    SimpleShowLayoutProps,
    ReferenceField,
    FunctionField,
    Link,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import PriceDisplayCurrencyContext from "./PriceDisplayCurrencyContext";
import {getEnvReceiptUrl} from "../EnvUtils";
import {CloverChargeReceiptIframe,} from "./CloverReceiptIframe";
import {RECEIPT_TYPES} from "../Constants";

interface CloverEcomChargeShowProps extends SimpleShowLayoutProps {
}


const CloverEcomChargeShow = (props: CloverEcomChargeShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>
            <Typography variant="h6" component="h3" gutterBottom>
                CloverEcomCharge
            </Typography>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <PriceDisplayCurrencyContext label={"amount"} sourceKey={"amount"}/>

            <DateField source="clover_created_at"/>

            <ReferenceField source="clover_ecom_refund_id" reference="clover_ecom_refunds" emptyText="Coming soon..."/>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

            <br/>

            <Typography variant="h6" component="h3" gutterBottom>
                Clover Relationships
            </Typography>

            <ReferenceField source="clover_merchant_id" reference="clover-merchants" emptyText={"Coming soon..."}
                            link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source="clover_ecom_customer_id" reference="clover-ecom-customers">
                <TextField source="uuid"/>
            </ReferenceField>

            <ReferenceOneField label={"Clover Ecom Order"} target={"clover_ecom_charge_id"}
                               reference={"clover-ecom-orders"}
                               emptyText={"No charge found! Perhaps this order has not been paid yet!"} link={"show"}>
                <TextField source={"uuid"} label={"uuid"}/>
            </ReferenceOneField>


            <FunctionField
                label={"Link to Clover Charge Receipt"}
                render={(record: { uuid: string }) => {
                    return (
                        <div>
                            <div>
                                <Link to={getEnvReceiptUrl(record.uuid, RECEIPT_TYPES.CHARGE)}>
                                    View Official Clover Charge Receipt (Publicly Available)
                                </Link>
                            </div>
                            <CloverChargeReceiptIframe uuid={record.uuid}/>
                        </div>
                    );
                }}
            />

        </SimpleShowLayout>
    </Show>
}

export default CloverEcomChargeShow;
