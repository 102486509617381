import React from 'react';
import {
    ReferenceArrayField,
    ReferenceField,
    ReferenceOneField,
    ShowButton,
    useRecordContext
} from 'react-admin';
import {cleanModelNameToEndpoint, stripNamespace} from "../ModelNameUtils";

interface ReferencePolymorphicFieldProps {
    label: string;
    sourceKey: string;
    typeKey: string;
}

const ReferencePolymorphicField: React.FC<React.PropsWithChildren<ReferencePolymorphicFieldProps>> = (props) => {
    const {typeKey, sourceKey, label, children} = props;
    const record = useRecordContext();

    if (!record) return null;
    const typeValue = record[typeKey];

    return (
        <ReferenceField source={sourceKey} reference={cleanModelNameToEndpoint(typeValue)} label={label}>
            <>
                <span>{stripNamespace(typeValue)}</span>
                <ShowButton/>
                {children}
            </>
        </ReferenceField>
    );
};

const ReferencePolymorphicArrayField: React.FC<React.PropsWithChildren<ReferencePolymorphicFieldProps>> = (props) => {
    const {typeKey, sourceKey, label, children} = props;
    const record = useRecordContext();

    if (!record) return null;
    const typeValue = record[typeKey];

    return (
        <ReferenceArrayField source={sourceKey} reference={cleanModelNameToEndpoint(typeValue)} label={label}>
            <>
                <span>{stripNamespace(typeValue)}</span>
                <ShowButton/>
                {children}
            </>
        </ReferenceArrayField>
    );
};

const ReferenceOnePolymorphicField: React.FC<React.PropsWithChildren<ReferencePolymorphicFieldProps>> = (props) => {
    const {typeKey, sourceKey, label, children} = props;
    const record = useRecordContext();

    if (!record) return null;
    const typeValue = record[typeKey];

    return (
        <ReferenceOneField source={"id"} reference={cleanModelNameToEndpoint(typeValue)} label={label}
                           target={sourceKey}>
            <>
                <span>{stripNamespace(typeValue)}</span>
                <ShowButton/>
                {children}
            </>
        </ReferenceOneField>
    );
};


export {ReferencePolymorphicField, ReferencePolymorphicArrayField, ReferenceOnePolymorphicField};
