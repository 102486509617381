import { SavedQueriesList, FilterLiveSearch, FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import MailIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import CategoryIcon from '@mui/icons-material/LocalOffer';

// @ts-ignore
export const CloverMerchantFilterSidebar = () => (
    <Card sx={{order: -1, mr: 2, mt: 9, width: 200}}>
        <CardContent>
            <SavedQueriesList/>
            <FilterLiveSearch/>
            <FilterList label="website" icon={<MailIcon/>}>
                <FilterListItem label="Yes" value={{website: 'IS_NOT_NULL'}}/>
                <FilterListItem label="No" value={{website: 'IS_NULL'}}/>
            </FilterList>
            <FilterList label="phone number" icon={<PhoneIcon/>}>
                <FilterListItem label="Yes" value={{phone_number: 'IS_NOT_NULL'}}/>
                <FilterListItem label="No" value={{phone_number: 'IS_NULL'}}/>
            </FilterList>
        </CardContent>
    </Card>
);
