import React from 'react';
import {NumberField, useRecordContext} from 'react-admin';

interface PriceDisplayCurrencyContextProps {
    label: string;
    sourceKey: string;
}

const PriceDisplayCurrencyContext: React.FC<PriceDisplayCurrencyContextProps> = (props) => {
    const {sourceKey, label} = props;
    const record = useRecordContext();

    if (!record) return null;

    if (record.amount) {
        record.adjustedAmountFromCents = record.amount / 100;
    }

    const source: string = record.amount ? "adjustedAmountFromCents" : sourceKey;

    return (
            <NumberField
                source={source}
                options={{
                    minimumFractionDigits: 2,
                    ...(record.currency && { style: 'currency', currency: record.currency })
                }}
                label={label}
            />
        );
};

export default PriceDisplayCurrencyContext;
