import React from 'react';
import {
    Show,
    TextField,
    DateField,
    SimpleShowLayout,
    SimpleShowLayoutProps,
    ReferenceField,
    BooleanField,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import {ReferencePolymorphicField} from "./ReferencePolymorphicField";

interface EventShowProps extends SimpleShowLayoutProps {

}

const EventShow = (props: EventShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>
            <Typography variant="h6" component="h3" gutterBottom>
                Event
            </Typography>

            <TextField source="id"/>

            <ReferencePolymorphicField label={"Event Type"} sourceKey={"eventable_id"} typeKey={"eventable_type"}/>

            <ReferenceField source="integration_id" reference="integrations" link={"show"}>
                <TextField source="domain"/>
            </ReferenceField>

            <TextField source="ip_address"/>

            <TextField source="user_agent"/>

            <BooleanField source="notification_sent" label="Notification Sent?" looseValue={true}/>

            <DateField source="created_at"/>

            <DateField source="updated_at" sortByOrder="DESC"/>

        </SimpleShowLayout>
    </Show>
}

export default EventShow;
