import {
    Datagrid,
    List,
    ReferenceField, ResourceProps,
    ShowButton,
    TextField,
    UrlField
} from 'react-admin';
import React from "react";
import {IntegrationFilterSidebar} from "./IntegrationFilterSidebar";
import {ReferencePolymorphicField} from "./ReferencePolymorphicField";

interface IntegrationListProps extends ResourceProps {

}

export const IntegrationList = (props: IntegrationListProps) => (
    <List {...props} aside={<IntegrationFilterSidebar/>}>
        <Datagrid bulkActionButtons={false}>

            <TextField source="id"/>

            <UrlField source="domain" label="Website"/>

            <TextField source="uuid"/>

            <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source={"clover_employee_id"} reference={"clover-employees"} link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source="clover_app_id" reference="clover-apps" link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferencePolymorphicField label={"Integrating Software"} sourceKey={"integrable_id"}
                                       typeKey={"integrable_type"}/>

            <ReferencePolymorphicField label={"Integrating Framework"} sourceKey={"integrable_backend_id"}
                                       typeKey={"integrable_backend_type"}/>

            <ShowButton/>

        </Datagrid>
    </List>
);
