import {
    Datagrid,
    DateField,
    List,
    ReferenceField, ResourceProps,
    ShowButton,
    TextField,
} from 'react-admin';
import React from "react";
import CloverEcomChargeFilterSidebar from "./CloverEcomChargeFilterSidebar";
import PriceDisplayCurrencyContext from "./PriceDisplayCurrencyContext";

interface CloverEcomChargeListProps extends ResourceProps {
}

export const CloverEcomChargeList = (props: CloverEcomChargeListProps) => (
    <List {...props} aside={<CloverEcomChargeFilterSidebar/>}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>

            <TextField source="id"/>

            <TextField source="uuid"/>

            <PriceDisplayCurrencyContext label={"amount"} sourceKey={"amount"}/>

            <TextField source="currency"/>

            <ReferenceField source="clover_ecom_order_id" reference="clover-ecom-orders" link={"show"}>
                <TextField source="uuid"/>
            </ReferenceField>

            <ReferenceField source="clover_ecom_refund_id" reference="clover_ecom_refunds"
                            emptyText={"Coming soon..."}/>

            <ReferenceField source="clover_ecom_customer_id" reference="clover-ecom-customers">
                <TextField source="uuid"/>
            </ReferenceField>

            <DateField source="clover_created_at"/>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

            <ShowButton/>
        </Datagrid>
    </List>
);
