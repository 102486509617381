import React, { useState } from 'react';
import {getEnvReceiptUrl} from "../EnvUtils";
import {RECEIPT_TYPES} from "../Constants";

interface CloverReceiptIframeProps {
    uuid: string;
    receiptType: typeof RECEIPT_TYPES[keyof typeof RECEIPT_TYPES];
}

const CloverReceiptIframe: React.FC<CloverReceiptIframeProps> = ({ uuid, receiptType }) => {
    const [iframeError, setIframeError] = useState(false);
    const srcUrl: string = getEnvReceiptUrl(uuid, receiptType);

    return (
        <div style={{ width: '100vw', height: '100vh', margin: 0, padding: 0, display: 'flex' }}>
            {iframeError ? (
                <div style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
                    <p>Failed to load the receipt. Please try again later.</p>
                </div>
            ) : (
                <iframe
                    src={srcUrl}
                    title={`${receiptType}-receipt-transaction`}
                    style={{ width: '350px', height: '100%', border: 'none' }}
                    onError={() => setIframeError(true)}
                ></iframe>
            )}
        </div>
    );
};

export const CloverOrderReceiptIframe: React.FC<{ uuid: string }> = ({ uuid }) => (
    <CloverReceiptIframe uuid={uuid} receiptType={RECEIPT_TYPES.ORDER} />
);

export const CloverChargeReceiptIframe: React.FC<{ uuid: string }> = ({uuid}) => (
    <CloverReceiptIframe uuid={uuid} receiptType={RECEIPT_TYPES.CHARGE} />
);