import React from 'react';
import {
    Show,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    SimpleShowLayout,
    ReferenceManyField,
    SimpleShowLayoutProps, ReferenceField, ShowButton,
    Pagination,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import {ReferencePolymorphicField} from "./ReferencePolymorphicField";

interface IntegrationShowProps extends SimpleShowLayoutProps {

}

const IntegrationShow = (props: IntegrationShowProps) => {
    return <Show>
        <SimpleShowLayout {...props}>

            <Typography variant="h6" component="h3" gutterBottom>
                Integration
            </Typography>

            <TextField source="uuid"/>

            <UrlField source="domain" label="Website"/>
            <br/>

            <Typography variant="h6" component="h3" gutterBottom>
                Clover Relationships
            </Typography>

            <ReferenceField source="clover_merchant_id" reference="clover-merchants" link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source="clover_employee_id" reference="clover-employees" link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceField source="clover_app_id" reference="clover-apps" link="show">
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceManyField reference={'events'} target={'integration_id'} label="Events" perPage={5}
                                pagination={<Pagination/>}>
                <Datagrid bulkActionButtons={false}>

                    <ReferencePolymorphicField label={"Event Type"} sourceKey={"eventable_id"}
                                               typeKey={"eventable_type"}/>
                    <DateField source="created_at"/>
                    <ShowButton/>

                </ Datagrid>
            </ReferenceManyField>

            <DateField source="created_at"/>

            <DateField source="updated_at"/>

        </SimpleShowLayout>
    </Show>
}

export default IntegrationShow;
